export const textToDateTime = (textDate, textTime) => {
  let [year, month, day] = textDate.includes("-")
    ? textDate.split("-")
    : textDate.split("/");
  month = month - 1;
  const date =  new Date(year, month, day);
  if (textTime) {
    let [hour, minute] = textTime.split(":");
    date.setHours(hour, minute);
  }
  return date;
};
