// External Imports
import { differenceInDays, format } from "date-fns";
import { useEffect, useState } from "react";
import { BiSolidChevronLeft, BiSolidChevronRight } from "react-icons/bi";
import { textToDateTime } from "../../util/textToDateTime";
import { BsCheck, BsX } from "react-icons/bs";

/** ========================================================================
 * * DateFilter Component
 * This component renders a form to flter a list by start and end date.
 * ========================================================================== */

export default function DateFilter({ onSubmit, startDate: sd, endDate: ed }) {
  const [period, setPeriod] = useState(sd && ed ? "Custom" : "All");
  const startDate = sd ? new Date(Number(sd)) : null;
  const endDate = ed ? new Date(Number(ed)) : null;

  // =================== SELECT CUSTOM RANGE ===================
  const selectDateRange = (e) => {
    e.preventDefault();
    // Get start and end date text input
    const start = e.target.start.value;
    const end = e.target.end.value;

    // Validate date format
    const regex = /^\d{4}[-/](0[1-9]|1[0-2])[-/](0[1-9]|[12][0-9]|3[01])$/;
    if (!regex.test(start) || !regex.test(end)) return;

    // Convert text to date
    let startDate = textToDateTime(start, "00:00");
    let endDate = textToDateTime(end, "23:59");

    // If end date is before start date, start date to end date sod
    if (endDate < startDate) startDate = textToDateTime(end, "00:00");

    // Submit the date range
    onSubmit(startDate.getTime(), endDate.getTime());
    console.log("Custom Range Selected");
    setPeriod("Custom");
  };

  // =================== NEXT PERIOD HANDLER ===================
  const nextPeriod = () => {
    if (!startDate || !endDate) return;
    const numDays = differenceInDays(endDate, startDate);

    // New start date is one date after the end date
    const newStartDate = new Date(endDate);
    newStartDate.setHours(0, 0, 0, 0);
    newStartDate.setDate(newStartDate.getDate() + 1);

    // New end date is numDays after the new start date
    const newEndDate = new Date(newStartDate);
    newEndDate.setHours(23, 59, 59, 999);
    newEndDate.setDate(newEndDate.getDate() + numDays);

    onSubmit(newStartDate.getTime(), newEndDate.getTime());
    setPeriod("Custom");
  };

  // =================== LAST PERIOD HANDLER ===================
  const lastPeriod = () => {
    if (!startDate || !endDate) return;
    const numDays = differenceInDays(endDate, startDate);

    // New end date is one day before the start date
    const newEndDate = new Date(startDate);
    newEndDate.setHours(23, 59, 59, 999);
    newEndDate.setDate(newEndDate.getDate() - 1);

    // New start date is numDays before the new end date
    const newStartDate = new Date(newEndDate);
    newStartDate.setHours(0, 0, 0, 0);
    newStartDate.setDate(newStartDate.getDate() - numDays);

    onSubmit(newStartDate.getTime(), newEndDate.getTime());
    setPeriod("Custom");
  };

  // =================== CHANGE PERIOD =================== //
  const changePeriod = () => {
    let newPeriod = "";
    if (period === "Custom") newPeriod = "Today";
    else if (period === "Today") newPeriod = "Week";
    else if (period === "Week") newPeriod = "Month";
    else if (period === "Month") newPeriod = "Year";
    else if (period === "Year") newPeriod = "All";
    else if (period === "All") newPeriod = "Today";
    else newPeriod = "All";

    const today = new Date();
    today.setHours(23, 59, 59, 999);
    const lastWeek = new Date();
    lastWeek.setDate(lastWeek.getDate() - 7);
    lastWeek.setHours(0, 0, 0, 0);

    if (newPeriod === "Week") onSubmit(lastWeek.getTime(), today.getTime());

    if (newPeriod === "Month") {
      const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
      onSubmit(firstDay.getTime(), today.getTime());
    }

    if (newPeriod === "Year") {
      const firstDay = new Date(today.getFullYear(), 0, 1);
      onSubmit(firstDay.getTime(), today.getTime());
    }

    if (newPeriod === "Today") {
      const sod = new Date(today);
      sod.setHours(0, 0, 0, 0);
      onSubmit(sod.getTime(), today.getTime());
    }

    if (newPeriod === "All") onSubmit();

    setPeriod(newPeriod);
  };

  /* =================== CHANGE DEFAULT DATE =================== 
  This handles changing displayed info for changes
  that come from outside the component.
  */
  useEffect(() => {
    if (!sd || !ed) {
      setPeriod("All");
      document.getElementsByName("start")[0].value = "";
      document.getElementsByName("end")[0].value = "";
    } else {
      document.getElementsByName("start")[0].value = format(
        startDate,
        "yyyy-MM-dd"
      );
      document.getElementsByName("end")[0].value = format(
        endDate,
        "yyyy-MM-dd"
      );
    }
  }, [sd, ed]);

  return (
    <form onSubmit={selectDateRange} className="flex items-center space-x-4">
      <BiSolidChevronLeft onClick={lastPeriod} className="icon" />
      <input
        type="date"
        className="text-sm outline-none cursor-pointer hover:text-stone-400 bg-stone-900"
        name="start"
        onClick={() => setPeriod()}
      />
      {period ? (
        <p
          onClick={changePeriod}
          className="text-sm cursor-pointer hover:text-stone-400"
        >
          {period}
        </p>
      ) : (
        <div className="flex items-center space-x-2">
          <BsX onClick={() => changePeriod()} className="icon" />
          <button
            type="submit"
            className="text-sm cursor-pointer hover:text-stone-400"
          >
            <BsCheck className="icon" />
          </button>
        </div>
      )}
      <input
        type="date"
        name="end"
        className="text-sm outline-none cursor-pointer hover:text-stone-400 bg-none bg-stone-900"
        onClick={() => setPeriod()}
      />
      <BiSolidChevronRight onClick={nextPeriod} className="icon" />
    </form>
  );
}
