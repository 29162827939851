// External Packages
import { useState, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { BsFilter, BsPlusLg } from "react-icons/bs";
import { MdRefresh } from "react-icons/md";
import { BiDownload } from "react-icons/bi";

// Internal Packages
import { DataContext } from "../../context/data-context";
import { UtilityContext } from "../../context/util-context";
import {
  timestampToDate,
  timestampToString,
} from "../../util/timestamp-to-date";
import { arrayToCsv } from "../../util/arrayToCsv";
import LoadingSpinnerWrapper from "../UI/LoadingSpinnerWrapper";
import SearchBar from "../UI/SearchBar";
import PaymentCard from "./PaymentCard";
import Heading from "../UI/Heading";
import FilterMenu from "../UI/FilterMenu";
import PaginationMenu from "../UI/PaginationMenu";
import DateFilter from "../UI/DateFilter";

/*
  * All Payments Page
  This page displays all payments in the database.
*/

export default function PaymentsPage() {
  const { navigate } = useContext(UtilityContext);
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  // Load Context
  const { payments, loadingData, fetchPayments } = useContext(DataContext);

  // Default Filter
  const defaultFilter = {
    search: params.get("search") ?? "",
    type: params.get("type") ?? "All",
    status: params.get("status") ?? "All",
    sd: params.get("sd") ?? "",
    ed: params.get("ed") ?? "",
  };

  // Initialize States
  const [filtered, setFiltered] = useState(payments);
  const [filter, setFilter] = useState({ ...defaultFilter });

  /* ======================= MAIN FILTER HANDLER ======================= */
  const applyFilters = () => {
    let records = [...payments];
    // Apply Options filter
    records = optionsFilter(records);

    // Apply Date filter
    if (filter.sd && filter.ed) records = dateFilter(records);

    // Apply Search filter
    if (filter.search) records = searchFilter(records);

    setFiltered([...(records ?? [])]);

    // Replace URL
    navigate(
      `/payments?search=${filter.search || ""}&type=${
        filter.type || ""
      }&status=${filter.status || ""}&sd=${filter.sd || ""}&ed=${
        filter.ed || ""
      }`,
      {},
      { replace: true }
    );
  };

  // =================== CLEAR ALL FILTERS ===================
  const clearFilters = () => {
    setFilter({ search: "", type: "All", status: "All", sd: "", ed: "" });
  };

  // =================== FILTER OPTIONS ===================
  const filterOptions = [
    {
      label: "Status",
      options: ["All", "Succeeded", "Pending", "Refunded", "Delinquent"],
      selected: filter.status || "All",
    },
    {
      label: "Type",
      options: ["All", "Payment", "Invoice"],
      selected: filter.type || "All",
    },
  ];

  //  =================== FILTER BY OPTIONS ===================
  const optionsFilter = (records) => {
    let filtered = [];
    records.forEach((payment) => {
      let flag = true;
      if (
        filter.status !== "All" &&
        payment.status !== filter.status.toLowerCase()
      )
        flag = false;
      if (filter.type !== "All" && payment.type !== filter.type.toLowerCase())
        flag = false;
      if (flag) filtered.push(payment);
    });
    return filtered;
  };

  //  =================== FILTER BY DATE ===================
  const dateFilter = (records) => {
    const startDate = new Date(Number(filter.sd));
    const endDate = new Date(Number(filter.ed));
    return records.filter((record) => {
      if (
        timestampToDate(record.date_created) < startDate ||
        timestampToDate(record.date_created) > endDate
      )
        return false;
      else return true;
    });
  };

  //  =================== FILTER BY SEARCH ===================
  const searchFilter = (records) => {
    return records.filter((payment) => {
      const string =
        `${payment.customer.firstName} ${payment.customer.lastName} ${payment.customer.email} 
    ${payment.customer.phone} ${payment.description} ${payment.amount} ${payment.vehicle?.make}
    ${payment.vehicle?.model} ${payment.vehicle?.year}
    `.toLowerCase();
      let flag = true;
      // Loop over all words in query so that order is irrelevant
      filter.search?.split(" ").forEach((word) => {
        if (!string.includes(word.toLowerCase())) flag = false;
      });
      return flag;
    });
  };

  // =================== DOWNLOAD CSV ===================
  const downloadCSV = () => {
    const arr = [];
    filtered.forEach((payment) => {
      arr.push({
        id: payment.id,
        customer: `${payment.customer.firstName} ${payment.customer.lastName}`,
        email: payment.customer.email,
        phone: payment.customer.phone,
        description: payment.description,
        amount: payment.amount,
        date: timestampToString(payment.date_created, "MM/dd/yyyy hh:mm a"),
        due: timestampToString(payment.due_date, "MM/dd/yyyy hh:mm a"),
        status: payment.status,
      });
    });
    arrayToCsv(arr, "payments");
  };

  // =================== APPLY FILTERS ===================
  useEffect(() => {
    applyFilters();
  }, [filter]);

  return (
    <div className="container grid">
      <Heading title="Payments" isBackArrow={false}>
        <DateFilter
          onSubmit={(sd, ed) => setFilter({ ...filter, sd, ed })}
          startDate={filter.sd}
          endDate={filter.ed}
        />
        <FilterMenu
          options={filterOptions}
          onSubmit={(f) =>
            setFilter({ ...filter, ...f })
          }
        />
        
        <BiDownload
          onClick={downloadCSV}
          className="text-xl text-white cursor-pointer hover:text-stone-400"
        />
        <BsPlusLg
          onClick={(e) => navigate("/payments/add", e)}
          className="text-2xl icon"
          title="Add Payment"
        />
        <MdRefresh
          onClick={fetchPayments}
          className="text-2xl text-white cursor-pointer hover:text-stone-400"
          title="Refresh"
        />
      </Heading>

      <SearchBar
        searchHandler={(search) => setFilter({ ...filter, search })}
        clearSearch={clearFilters}
        searchValue={filter.search}
      />

      <div className="list">
        {loadingData ? (
          <LoadingSpinnerWrapper />
        ) : (
          <div className="grid gap-8">
            {filtered.map((payment) => (
              <PaymentCard key={payment.id} payment={payment} />
            ))}
          </div>
        )}
      </div>

      <div className="flex justify-between text-sm text-stone-200">
        {filtered.length !== payments.length && (
          <p>
            Total $
            {filtered
              .reduce((acc, payment) => acc + Number(payment.amount), 0)
              .toFixed(2)}
          </p>
        )}
        <PaginationMenu showing={filtered.length} total={payments.length} />
      </div>
    </div>
  );
}
